<template>
    <transition name="fade">
        <div v-if="open" class="modal-background"></div>
    </transition>
    <transition name="slide-fade">
        <div v-if="open" class="modal" :class="{ open }"  @click="backgroundOnClick">
            <div class="modal-frame" ref="frame" v-bind="$attrs">
                <span v-if="floatingClose" @click.stop="close" class="floatingClose">
                    <w3ppClose />
                </span>
                <div class="modal-header" :class="{ floatingClose }"><slot name="modal-header"></slot></div>
                <div class="modal-body"><slot name="default"></slot></div>
                <div class="modal-footer"><slot name="modal-footer"></slot></div>
            </div>
        </div>
    </transition>
</template>
<script>
import w3ppClose from '@/components/w3ppClose'
export default {
    components: {
        w3ppClose
    },
    props: {
        open: Boolean,
        floatingClose: Boolean
    },
    emits: ['backgroundClick', 'close'],
    methods: {
        backgroundOnClick (event) {
            if (this.$refs.frame === event.target || this.$refs.frame.contains(event.target)) {
                event.preventDefault()
            } else {
                this.$emit('backgroundClick')
            }
        },
        close () {
            this.$emit('close')
        }
    }
}
</script>
<style lang="scss" scoped>
.modal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

span.floatingClose {
    position: absolute;
    right: 4px;
    top: 4px;
}

.modal {
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .modal-frame {
        bottom: 0px;
        left: 0;
        display: flex;
        flex-direction: column;
        background: white;
        box-shadow: rgba(0, 0, 0, .15) 0px 3px 16px 0px;
        border-radius: 8px;
        position: relative;
        min-height: 72px;
        max-height: #{"calc(100% - 36px)"};
        width: auto;
        min-width: 240px;
        max-width: #{"min(400px, calc(100% - 12px))"};
        border-radius: 4px;

        .modal-header {
            flex-shrink: 0;

            &.floatingClose {
                padding-right: 36px;
            }
        }
        .modal-body {
            overflow: auto;
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        .modal-footer {
            flex-shrink: 0;
        }
    }
}
</style>

<style lang="scss" scoped>
    .slide-fade-enter-active,
    .slide-fade-leave-active {
        transition: all 0.2s ease-out;
    }

    .slide-fade-enter-from,
    .slide-fade-leave-to {
        transform: scale(.85);
        opacity: 0;
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
</style>

<template>
  <div class="body">
    <div class="banner">
      <div class="header">Your <span class="free">free</span> daily expense tracker</div>
      <p class="sub-text">
        Manage your money the best and
        OG way - by tracking it. Minimum effort, maximum a-ha’s!
      </p>
    </div>
    <w3ppButton @click="signinWithGoogle"> Get Started </w3ppButton>
  </div>

  <div class="section">
    <div class="section-block">
      <h2 class="section-heading">Start spending less and saving more</h2>
      <p class="section-content">
        With Spenda, it's quick, easy and personalised
      </p>
    </div>
    <div class="benefits-grid">
      <div class="benefit-block blue">
        <h3 class="benefit-heading">Track your spending 🛍</h3>
        <p class="benefit-content">
          Easy daily tracking, anytime, on any device
        </p>
      </div>
      <div class="benefit-block teal">
        <h3 class="benefit-heading teal">Get great insights 📈</h3>
        <p class="benefit-content">
          Real time personalised analytics to give you maximum insights
        </p>
      </div>
      <div class="benefit-block pink">
        <h3 class="benefit-heading pink">Make better decisions ✅</h3>
        <p class="benefit-content">
          Interactive functionality to help you become a better spender
        </p>
      </div>
    </div>
    
  </div>
  <div class="section">
    <div class="section-block">
      <h2 class="section-heading">Built for everyone</h2>
      <p class="section-content">
        No bank card? No problem. Spenda doesn't require a bank card. And with close to no data usage, we make it inclusive and accessible to all. Empowering all people to be better spenders and savers.
      </p>
    </div>
  </div>
  <div class="section footer">
    <div class="footer-grid">
      <div class="footer-block">
        <h2 class="footer-heading">
            <img class="spenda-img" src="@/assets/logo.svg" alt="Spenda"/>
            Spenda
        </h2>
        <div class="footer-content">
            <p>
                Made with ❤️  in a 🏡  in Cape Town
            </p>
            <p>
                &copy; 2021 All rights reserved
            </p>
        </div>
      </div>
      <div class="footer-block">
        <h3 class="footer-heading">Let's chat</h3>
        <p class="footer-content">
            <a
                href="mailto://hello@getspenda.co.za"
                target="_blank"
            >
                hello@getspenda.co.za
            </a>
        </p>
        <h3 class="footer-heading">Stay in touch</h3>
        <p class="footer-content">
            <a
                href="https://www.instagram.com/spenda_za/"
                target="_blank"
            >
                Insagram
            </a>
        </p>
      </div>
    </div>
    
  </div>
  

  <w3ppDialog :open="loading">
    <div class="loading-body">
      <icon icon="loadingIcon" :inline="true" class="loading-icon" />
      Signing in...
    </div>
  </w3ppDialog>
</template>

<script>
import firebase from "@/firebase";
import w3ppDialog from "@/components/w3ppDialog";
import w3ppNavbar from "@/components/w3ppNavbar";
import w3ppButton from "@/components/w3ppButton";
import { Icon, addIcon } from "@iconify/vue";
import loadingIcon from "@iconify-icons/mdi/loading";

addIcon("loadingIcon", loadingIcon);

export default {
  components: {
    Icon,
    w3ppDialog,
    w3ppNavbar,
    w3ppButton,
  },
  data() {
    return {
      email: "",
      password: "",
      loading: false,
    };
  },
  methods: {
    signinWithGoogle() {
      this.loading = true;
      firebase
        .auth()
        .signInWithPopup(new firebase.auth.GoogleAuthProvider())
        .catch(() => {})
        .then(this.done);
    },
    error(error) {
      this.loading = false;
      this.$snackBars.set("error", {
        message: error.message,
        action: {
          label: "Okay!",
          fn: () => this.$snackBars.delete("error"),
        },
      });
    },
    async done(response) {
      this.loading = false;
      if (response) {
        this.$router.push("/");

        if (!this.$snackBars.has("sign-in")) {
          this.$snackBars.show("sign-in", {
            message: "Successfully signed in",
            action: {
              label: "Okay!",
              fn: () => this.$snackBars.delete("sign-in"),
            },
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.body {
  font-size: calc(10px + 0.41667vw);
  padding: 8vh 10vw 10vh 10vw;
  position: relative;
  &:before {
    content: "";
    background: url("~@/assets/phone-hand.png");
    background-size: contain;
    background-repeat: no-repeat;
    display: block;
    width: 50%;
    height: 80vh;
    position: absolute;
    background-position: right bottom;
    right: 36px;
    bottom: -4%;
    z-index: 1;

    @media (max-width: 430px) {
      bottom: 7%;
    }

    @media (min-width: 720px) {
      z-index: 0;
      right: 36px;
      top: -8px;
      background-position: right top;
    }
  }
  &:after {
    content: "";
    display: block;
    background: url("~@/assets/color-blob.svg");
    background-size: contain;
    background-position: 5% 20%;
    background-repeat: no-repeat;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    z-index: 0;
    top: 0px;
    opacity: 0.9;

    @media (min-width: 720px) {
      top: 36px;
    }
  }
  .banner {
    position: relative;
    z-index: 1;
    .header {
      max-width: 16ch;
      font-weight: 900;
      font-size: 3.2em;

      @media (min-width: 720px) {
        font-size: 4em;
      }
    }

    .sub-text {
      font-size: 1.5em;
      margin-top: 24px;
      max-width: 28ch;
    }
  }

  .button {
    position: relative;
    font-size: 1.5em;
    padding: 16px 36px;
    margin-top: 72px;
    font-weight: 700;
    z-index: 1;
  }
}

.section {
    font-size: calc(10px + 0.41667vw);
    background: rgba(255, 255, 255, .9);
    position: relative;
    padding: 8vh 8vw 8vh 8vw;

    & + .section {
        background: transparent;
    }

    @media (min-width: 720px) {
        padding: 8vh 3vw 8vh 3vw;
    }
    @media (min-width: 1200px) {
        padding: 12vh 8vw 12vh 8vw;
    }
    .section-block {
        font-size: 2.2em;
        text-align: center;

        .section-content {
            padding-top: 1em;

            font-size: .7em;
            @media (min-width: 720px) {
                font-size: .55em;
            }
        }

    }
    .benefits-grid {
        display: flex;
        flex-direction: column;
        // box-shadow: 0 0 6px 0 rgba(100, 121, 143, .2), 0 0 2px rgba(0, 0, 0, .1);
        border-radius: 36px;
        margin-top: 36px;
        gap: 24px;
        @media (min-width: 900px) {
            flex-direction: row;
        }
        .benefit-block {
            // background: #f2f2f2;
            box-shadow: 0 1px 2px rgba(0, 0, 0, .2);
            border-radius: 1.2em;
            padding: 1.5em 1em;
            text-align: center;
            font-size: 1.4em;
            flex: 1;

            @media (min-width: 720px) {
                font-size: 1.1em;
            }
            .benefit-heading {
                font-size: 1.3em;
                padding-bottom: .5em;
            }

            &.blue {
                background: #e4eefe;
                .benefit-heading {
                    color: #3887f7;
                }
            }
            &.teal {
                background: #b6fcdf;
                .benefit-heading {
                    color: #229c69;
                }
            }
            &.pink {
                background: #fee8f1;
                .benefit-heading {
                    color: #f73d8a;
                }
            }
        }
    }
}

.free {
    background: #ffff00;
}

.footer {
    box-shadow: inset 0 4px 2px rgba(100, 121, 143, .12);
    padding-top: 4em;
    padding-bottom: 2em;
    .footer-grid {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        gap: 24px;
        
        @media (min-width: 720px) {
            flex-direction: row;
        }
        
        .footer-heading {
            font-size: 1.3em;
            padding-bottom: .5em;
            display: flex;
            align-items: center;
            gap: 8px;
        }
        h2.footer-heading {
            font-size: 1.8em;
        }
        .footer-content {
            min-height: 32px;
            font-size: 16px;
            a {
                color: inherit;
                min-height: 48px;
                display: inilne-block;
                text-decoration: none;
                position: relative;
                &:after {
                    content: '';
                    position: absolute;
                    bottom: 0px;
                    height: 2px;
                    display: block;
                    background: orange;
                    width: 100%;
                }
            }
        }
    }
}

.spenda-img {
    height: 32px;
}
</style>

<style lang="scss" scoped>
.loading-body {
  text-align: center;
}

.loading-icon {
  animation: spin 1s infinite linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
</style>
